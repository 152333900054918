<template>
    <div class="inputs-wrapper">
        <div class="form-pair">
            <div class="settings-label pull-left">Storage Name</div>
            <div class="pull-left settings-field-long">
                <b-form-input
                        v-model="$v.form.storageAccount.$model"
                        class="settings-field settings-field-blue-bg"
                        :state="validateState('storageAccount')"
                        autocomplete="off"
                ></b-form-input>
            </div>
        </div>
        <div class="field-spacer">
            <b-form-invalid-feedback :tooltip="false">Storage Name is required.</b-form-invalid-feedback>
        </div>
        <div class="form-pair">
            <div class="settings-label pull-left">API Key</div>
            <div class="pull-left settings-field-long">
                <b-form-input
                        v-model="$v.form.storageKey.$model"
                        class="settings-field settings-field-blue-bg"
                        :state="validateState('storageKey')"
                        autocomplete="new-password"
                ></b-form-input>
            </div>
        </div>
        <div class="field-spacer">
            <b-form-invalid-feedback :tooltip="false">API Key is required.</b-form-invalid-feedback>
        </div>
        <div class="flex ml-auto row">
            <b-button class="transparent-button save-button" v-bind:disabled="$v.form.$invalid" variant="outline-primary" v-on:click="handleStorageSaveButtonClick()">SAVE</b-button>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from "vuelidate";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "AzureStorageConfigurationPanel",
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    storageAccount: '',
                    storageKey: ''
                },
            }
        },
        validations: {
            form: {
                storageAccount: {
                    required
                },
                storageKey: {
                    required
                }
            }
        },
        beforeMount(){
            this.$store.dispatch('getBlobStorageDetails');
        },
        mounted() {
            this.form = this.$store.state.settings.storageDetails;
        },
        beforeDestroy() {
            this.saveCallback(true);
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            handleStorageSaveButtonClick() {
                if(!this.$v.form.$invalid){
                let storageData = {
                    storageAccount: this.form.storageAccount,
                    storageKey: this.form.storageKey
                };
                this.$store.dispatch("updateBlobStorageDetails", {successCallback: this.saveCallback.bind(this), data: storageData})
                } else {
                    this.$store.commit("SHOW_MESSAGE", {
                       type: 1,
                       message: 'Please enter values in all required fields'
                    });
                 }
            },
            saveCallback(skipRouteChange) {
                this.$v.form.storageKey.$model = "";
                this.$v.form.$reset();
                if(!skipRouteChange){
                    this.$router.push("/")
                }
            },
        },
    }
</script>

<style scoped>

</style>
