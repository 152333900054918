<template>
    <div class="manage-settings-wrapper">
        <div class="form-wrapper">
          <div class="header-wrapper flex-row">
            <div class="header-center">
              <div class="header-title">SETTINGS</div>
              <b-icon-x class="header-cancel-button" v-on:click="$router.push('/')"></b-icon-x>
            </div>
          </div>
            <div class="form-body-wrapper">
                <b-tabs lazy>
                  <b-tab title="Storage" active>
                      <AzureStorageConfigurationPanel></AzureStorageConfigurationPanel>
                  </b-tab>
                  <b-tab title="Databricks">
                    <AzureDatabricksPanel></AzureDatabricksPanel>
                  </b-tab>
                  <b-tab title="Azure AD">
                    <AzureADPanel></AzureADPanel>
                  </b-tab>
                </b-tabs>

            </div>

        </div>
    </div>
</template>

<script>

    import AzureADPanel from "@/components/settings/AzureADPanel.vue";
    import AzureDatabricksPanel from "@/components/settings/AzureDatabricksPanel.vue";
    import AzureStorageConfigurationPanel from "@/components/settings/AzureStorageConfigurationPanel.vue";
    export default {
        name: "Settings.vue",
        components: { AzureADPanel, AzureDatabricksPanel, AzureStorageConfigurationPanel },
        mounted() {
          this.$store.commit('SHOW_HEADER', false);
        }
    }
</script>

<style lang="scss">

.manage-settings-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/earth_background.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    .ml-auto {
      margin-left: auto!important;
    }
    .form-wrapper {
      border-radius: 8px;
      min-width:600px;
        .invalid-tooltip {
            position: inherit;
        }
      .header-center {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
      }
        background-color: #212C38;
        overflow: hidden;
        .settings-button {
          padding-left: 20px;
          padding-right: 20px;
          margin-left: 20px;
          width: auto;
          height: 30px;
        }
        .settings-button:hover {
          background-color: #fff !important;
          color: #0A78CC !important;
        }

        .form-body-wrapper {
          padding: 20px;
        }
        .storage-settings-form-input {
          width: 70%;
        }
        .databricks-save-button-wrapper {
          clear:both;
          margin-top: 0px;
        }
        .header-wrapper {
          width: 100%;
        }
        .inputs-wrapper {
            background-color: #212C38;
            padding: 10px;
            height: 320px;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            .input-wr {
                display: flex;
                flex-direction: column;
                width: 300px;
                color: #e1ebf5;
                .form-group {
                    margin-bottom: 5px;
                    text-align: left;
                }
            }
        }
    }


  .field-spacer {
    clear: both;
    height: 10px;
  }
  .settings-label {
    width: 125px;
    color: #cfe2f2;
    font-size: 12px;
    margin-right: 12px;
    margin-top: 10px;
  }
  .settings-field {
    min-width: 100px;
    height: 26px;
    padding: 4px 4px 4px 5px;
    font-size: 12px;
    font-weight: 400;
    border: 0;
    margin-top: 8px;
  }
  .settings-field-long {
    width: 400px;
  }
  .settings-field:focus {
    border: none;
  }
  .settings-field-validate {
    border: 1px solid red !important;
  }
  .settings-field-blue-bg {
      background-color: rgb(33, 44, 56);
      border: 1px solid #4B5561;
      color: #fff !important;
  }
    .settings-field-blue-bg:focus {
        box-shadow: 0 0 0 0.2rem rgba(13, 150, 255, 1);
        border: none;
    }

  .nav-link {
    color: #777;
    background-color: #212C38;
    padding-top: 8px;
    font-size: 12px;
  }
  .nav-tabs .nav-link:hover {
    color: #0A78CC;
    border: 0px;
  }
  .nav-tabs .nav-link.active {
    background-color: #212C38 !important;
    color: #ddd !important;
    border-radius: 0;
    border-bottom: 0;
    padding-bottom: 12px;
  }
}
</style>
