<template>
    <div class="inputs-wrapper">
        <div class="form-pair">
            <div class="settings-label pull-left">Databricks URL</div>
            <div class="pull-left settings-field-long">
                <b-form-input
                        v-model="$v.form.address.$model"
                        class="settings-field settings-field-blue-bg"
                        :state="validateState('address')"
                        autocomplete="off"
                ></b-form-input>
            </div>
            <div class="field-spacer">
                <b-form-invalid-feedback :tooltip="false">Databricks URL is required.</b-form-invalid-feedback>
            </div>
            <div class="form-pair">
                <div class="settings-label pull-left">Port</div>
                <div class="pull-left settings-field-long">
                    <b-form-input
                            v-model="$v.form.port.$model"
                            class="settings-field settings-field-blue-bg"
                            :state="validateState('port')"
                            autocomplete="off"
                    ></b-form-input>
                </div>
            </div>
            <div class="field-spacer">
                <b-form-invalid-feedback :tooltip="false">Port Number is required.</b-form-invalid-feedback>
            </div>
            <div class="form-pair">
                <div class="settings-label pull-left">Org ID</div>
                <div class="pull-left settings-field-long">
                    <b-form-input
                            v-model="$v.form.orgId.$model"
                            :state="validateState('orgId')"
                            class="settings-field settings-field-blue-bg"
                            autocomplete="off"
                    ></b-form-input>
                </div>
            </div>
            <div class="field-spacer">
                <b-form-invalid-feedback :tooltip="false">Org ID is required.</b-form-invalid-feedback>
            </div>
            <div class="form-pair">
                <div class="settings-label pull-left">Cluster ID</div>
                <div class="pull-left settings-field-long">
                    <b-form-input
                            v-model="$v.form.clusterId.$model"
                            :state="validateState('clusterId')"
                            class="settings-field settings-field-blue-bg"
                            autocomplete="off"
                    ></b-form-input>
                </div>
            </div>
            <div class="field-spacer">
                <b-form-invalid-feedback :tooltip="false">Cluster ID is required.</b-form-invalid-feedback>
            </div>
            <div class="form-pair">
                <div class="settings-label pull-left">Access Key</div>
                <div class="pull-left settings-field-long">
                    <b-form-input
                            v-model="$v.form.apiToken.$model"
                            type="password"
                            :state="validateState('apiToken')"
                            class="settings-field settings-field-blue-bg"
                            autocomplete="new-password"
                    ></b-form-input>
                </div>
            </div>
            <div class="field-spacer">
                <b-form-invalid-feedback :tooltip="false">API Key is required.</b-form-invalid-feedback>
            </div>
            <div class="form-pair databricks-radios">
              <div class="settings-label pull-left">Cluster type</div>
              <div>
                <b-form-group>
                  <b-form-radio-group v-model="$v.form.clusterType.$model">
                    <b-form-radio value="cpu">CPU</b-form-radio>
                    <b-form-radio value="gpu">GPU</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>

        </div>
        <div class="flex row ml-auto">
            <b-button class="transparent-button save-button" v-bind:disabled="$v.form.$invalid" variant="outline-primary" v-on:click="handleDatabricksSaveClick()">SAVE</b-button>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from "vuelidate";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "AzureDatabricksPanel",
        mixins: [validationMixin],
        data() {
            return {

                form: {
                    address: '',
                    port: '',
                    apiToken: '',
                    orgId: '',
                    clusterId: '',
                    clusterType: 'cpu',
                }
            }
        },
        validations: {
            form: {
                address: {
                    required
                },
                port: {
                    required
                },
                apiToken: {
                    required
                },
                orgId: {
                    required
                },
                clusterId: {
                    required
                },
                clusterType: {
                  required
                }
            }
        },
        beforeDestroy() {
            this.saveCallback(true);
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            handleDatabricksSaveClick() {
              if(!this.$v.form.$invalid) {
                    let databricksData = {
                        address: this.form.address,
                        port: this.form.port,
                        apiToken: this.form.apiToken,
                        orgId: this.form.orgId,
                        clusterId: this.form.clusterId,
                        clusterType: this.form.clusterType
                    };

                    //additional check for Azure Storage Account Details
                    let storageAccount = this.$store.state.settings.storageDetails.storageAccount;
                    if (!storageAccount || storageAccount === '') {
                      this.$store.commit("SHOW_MESSAGE", {
                          type: 1,
                          message: 'Please enter valid Storage Account settings on the Storage tab before saving Databricks settings.'
                      });
                      return;
                    }

                    this.$store.dispatch("updateDatabricksDetails", {
                      successCallback: this.saveCallback.bind(this),
                        data: databricksData
                    })

                } else {
                    this.$store.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: 'Please enter values in all required fields'
                    });
                }
            },
            saveCallback(skipRouteChange) {
                this.$v.form.apiToken.$model = "";
                this.$v.form.$reset();
                if(!skipRouteChange){
                    this.$router.push("/")
                }
            }
        },
        beforeMount() {
            this.$store.dispatch('getDatabricksConfigDetails');
        },
        mounted() {
            this.form = this.$store.state.settings.databricksDetails
        },
    }
</script>

<style scoped lang="scss">
.databricks-radios {
  margin-top: 10px;
  .manage-settings-wrapper {
    .settings-label {
      margin-top: 3px;
    }
  }
  color: #fff;
}

</style>
