<template>
  <div class="inputs-wrapper">
    <div class="form-pair">
      <div class="form-pair">
        <div class="settings-label pull-left">Application (client) ID</div>
        <div class="pull-left settings-field-long">
          <b-form-input
                  v-model="$v.form.clientId.$model"
                  class="settings-field settings-field-blue-bg"
                  :state="validateState('clientId')"
                  autocomplete="off"
          ></b-form-input>
        </div>
      </div>
      <div class="field-spacer">
        <b-form-invalid-feedback :tooltip="false">ClientId is required.</b-form-invalid-feedback>
      </div>
      <div class="settings-label pull-left">Directory (tenant) ID</div>
      <div class="pull-left settings-field-long">
        <b-form-input
                v-model="$v.form.tenantId.$model"
                class="settings-field settings-field-blue-bg"
                :state="validateState('tenantId')"
                autocomplete="off"
        ></b-form-input>
      </div>
      <div class="field-spacer">
        <b-form-invalid-feedback :tooltip="false">Directory Id is required.</b-form-invalid-feedback>
      </div>
      <div class="form-pair">
        <div class="settings-label pull-left">Client Secret</div>
        <div class="pull-left settings-field-long">
          <b-form-input
                  v-model="$v.form.clientSecret.$model"
                  type="password"
                  class="settings-field settings-field-blue-bg"
                  :state="validateState('clientSecret')"
                  autocomplete="new-password"
          ></b-form-input>
        </div>
      </div>
      <div class="field-spacer">
        <b-form-invalid-feedback :tooltip="false">Client Secret is required.</b-form-invalid-feedback>
      </div>
    </div>
    <div class="flex row ml-auto">
      <b-button class="transparent-button save-button" variant="outline-primary" v-bind:disabled="$v.form.$invalid" v-on:click="handleSaveButtonClick()">SAVE</b-button>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
export default {
    name: "AzureADPanel",
    mixins: [validationMixin],
    data() {
      return {
        form: {
          clientId: '',
          clientSecret: '',
          tenantId: ''
        }
      };
    },
    validations: {
      form: {
          clientId: {
            required
          },
          clientSecret: {
            required
          },
          tenantId: {
            required
          }
      }
    },
    beforeMount() {
      this.$store.dispatch('getAzureADConfigDetails');
    },
    mounted() {
      this.form = this.$store.state.settings.aadDetails;
    },
    beforeDestroy() {
      this.saveCallback(true);
    },
  methods: {
      validateState(name) {
          const { $dirty, $error } = this.$v.form[name];
          return $dirty ? !$error : null;
      },
      handleSaveButtonClick() {
        if(!this.$v.form.$invalid) {
          let azureData = {
            clientId: this.form.clientId,
            clientSecret: this.form.clientSecret,
            tenantId: this.form.tenantId
          };
          this.$store.dispatch("updateAzureADDetails", {successCallback: this.saveCallback.bind(this), data: azureData})
        } else {
           this.$store.commit("SHOW_MESSAGE", {
               type: 1,
               message: 'Please enter values in all required fields'
           });
        }
      },
      saveCallback(skipRouteChange) {
        this.$v.form.clientSecret.$model = "";
        this.$v.form.$reset();
        if(!skipRouteChange){
          this.$router.push("/")
        }
      }
    }
}
</script>

<style>

</style>
